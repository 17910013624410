<template>
  <div class="px-3 py-8 text-lg text-gray-600 lg:py-12">

    <h2 v-if="headline" class="max-w-4xl mx-auto text-3xl font-bold text-green-400 text-center sm:text-4xl">
      {{ headline }}
    </h2>
    <p v-if="textUnderHeadlineOne" class="max-w-4xl mx-auto mt-8 text-lg leading-6 text-gray-600 text-center">
      {{ textUnderHeadlineOne }}
    </p>
    <p v-if="textUnderHeadlineTwo" class="max-w-4xl mx-auto mt-1 text-lg leading-6 text-gray-600 text-center">
      {{ textUnderHeadlineTwo }}
    </p>

    <div class="flex flex-col items-center justify-center sm:flex-row" :class="{'mt-8 sm:mt-12': textElements}">
      <p class="mr-4 mb-4 text-center font-semibold sm:mb-0">Wie hoch ist Ihre Mietkaution</p>
      <currency-input v-model="deposit"></currency-input>
    </div>

    <div class="mt-8 max-w-4xl sm:mr-auto sm:ml-auto sm:mt-12 ">
      <vue-slider v-model="slider"
                  v-bind="options"
                  v-on:drag-start="sliderUpdated = true"
      ></vue-slider>
    </div>

    <div class="hidden mt-8 justify-center items-end sm:mt-12 sm:flex">
      Ihr Beitrag beträgt: <span class="ml-1 text-5xl font-semibold sm:ml-3"> {{ monthlyPaymentText[0] }} </span>
      <span class="mr-1 text-3xl font-semibold"> ,{{ monthlyPaymentText[1] }} </span>
      <span class="mr-1 text-3xl font-semibold sm:mr-3"> € </span>monatlich
    </div>
    <div class="hidden justify-center sm:flex">
        (entspricht {{ yearlyPaymentText[0] }},{{ yearlyPaymentText[1] }} € jährlich)
    </div>

    <div class="mt-8 text-center sm:hidden">
      <div>
        Ihr monatlicher Beitrag beträgt:
      </div>
      <div class="mt-4 flex items-end justify-center">
        <span class="text-5xl font-semibold"> {{ monthlyPaymentText[0] }} </span>
        <span class="text-3xl font-semibold"> ,{{ monthlyPaymentText[1] }} €</span>
      </div>
      <div class="mt-4">
        (entspricht {{ yearlyPaymentText[0] }},{{ yearlyPaymentText[1] }} € jährlich)
      </div>
    </div>

    <div v-if="errorState" class="mt-8 flex justify-center sm:mt-12 ">
      <p class="w-3/4 bg-red-400 text-white text-sm text-center">
        die Kautionsumme ist zu hoch. Bürgschaft in dieser Höhe nicht möglich. Maximalhöhe: 7.500 €.
      </p>
    </div>

    <div class="mt-8 flex justify-center sm:mt-12">
      <a v-if="!errorState" :href="urlParam"
         class="whitespace-nowrap text-sm font-medium border border-transparent rounded-full text-white bg-green-400 px-4 py-3 text-center hover:bg-green-300" :class="textElements ? 'sm:w-1/3' : 'w-full'">
        Jetzt Preis sichern
      </a>
    </div>

  </div>

</template>

<style>
</style>

<script>
import vueSlider from 'vue-slider-component'
import currencyInput from './currencyInput.vue'
import {maxDeposit, minimumPrice, priceRate} from "../../common/utils/pricing";

export default {
  name: 'calculatorapp',
  components: {
    vueSlider,
    currencyInput
  },
  data() {
    return {
      errorState: false,
      deposit: 1000,
      slider: 1000,
      max: maxDeposit,
      sliderUpdated: false,
      targetButton: false,
      options: {
        dotStyle: {
          border: '5px solid #34D499',
        },
        processStyle: {
          background: '#34D499',
        },
        railStyle: {
          background: '#D1D5DA',
        },
        width: 'auto',
        height: 10,
        min: 300,
        max: maxDeposit,
        interval: 100,
        dotSize: 27,
        tooltip: 'none',
        lazy: true
      }
    }
  },
  props: {
    headline: String,
    textUnderHeadlineOne: String,
    textUnderHeadlineTwo: String,
  },
  watch: {
    deposit: function (val) {
      this.slider = Math.min(val, maxDeposit)
    },
    slider: function (val) {
      if (this.deposit < maxDeposit || this.sliderUpdated) {
        this.deposit = val
        this.sliderUpdated = false
      }
    }
  },
  computed: {
    textElements: function () {
      return this.headline || this.textUnderHeadlineOne || this.textUnderHeadlineTwo;
    },
    urlParam: function() {
      return this.deposit ? '/app/start?deposit=' + this.deposit : '/app/start';
    },
    yearlyPayment: function () {
      const yearlyCost = this.deposit < 1000 ? minimumPrice.toFixed(2) : (this.deposit / 100 * priceRate).toFixed(2);
      return yearlyCost

    },
    monthlyPayment: function () {
      const monthlyCost = (this.yearlyPayment/12).toFixed(2);
      return monthlyCost
    },

    monthlyPaymentText: function () {
      if (this.deposit <= this.max) {
        this.errorState = false
        return this.monthlyPayment.split(".");
      } else {
        this.errorState = true
        return '--'
      }
    },

    yearlyPaymentText: function () {
      if (this.deposit <= this.max) {
        this.errorState = false
        return this.yearlyPayment.split(".");
      } else {
        this.errorState = true
        return '--'
      }
    },
  },
}
</script>
